import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useReduxKey from '../../hooks/useReduxKey';
import useBooker25 from '../../hooks/useBooker25';
import timeslotsReducer from '../../reducers/timeslots';
import { getReservationServices } from '../../utils/services';
import getReservationData from '../../utils/booking';
import createPostMessage, { postMessageTypes } from '../../postMessageActions';
import { TrackingEventEnum } from '../../utils/analytics';

import ReservationConfirmation from '../ReservationConfirmation/index';
import PaymentProcess from '../PaymentProcess/index';

import { Reservation, ServicePrototype } from '../../types';
import useAnalytics from '../../hooks/useAnalytics';
import { FilterState } from '../../reducers/filter';
import { LanguageContext } from '../../contexts/LanguageProvider';

interface BookingProcessProps {
  loading: boolean;
  setError: (state: boolean) => void;
  setLoading: (state: boolean) => void;
}

export default function BookingProcess({
  setError, setLoading, loading }: BookingProcessProps): JSX.Element {
  const [postReservationResponse, setPostReservationResponse] = useState(null);
  const booker = useBooker25();
  const dispatch = useDispatch();
  const contactDetails = useReduxKey('details');
  const attendeeDetails = useReduxKey('attendees');
  const filterState = useReduxKey<FilterState>('filterState');
  const {
    additionalServices, includedServices,
  } = useReduxKey('services');
  const {
    contactId, leadId, accountId, showServicesStep,
    paymentsEnabled, parentOrigin,
  } = useReduxKey('configuration');
  const { trackEvent } = useAnalytics();

  const handleError = (erorrList) => {
    setError(true);
    if (process.env.NODE_ENV === 'development') {
      erorrList.forEach((error) => {
        console.error('Error on reservation:: ', error.message);
      });
    }
  };

  const sendReservationDetailsToParentWindow = (details) => {
    const reservationId = details.reservation?.id ?? details.id;
    const properties = details.reservation?.properties ?? {};
    window.parent.postMessage(
      createPostMessage(postMessageTypes.reservation, {
        id: reservationId,
        properties,
      }),
      parentOrigin,
    );
  };

  const { language } = useContext(LanguageContext);

  const postReservation = async () => {
    const reservationData = {
      language,
      ...getReservationData(
        filterState, contactId, leadId, accountId, contactDetails, attendeeDetails,
      ),
    };

    let services: ServicePrototype[] = [];
    if (showServicesStep && (additionalServices || includedServices)) {
      services = getReservationServices([
        ...additionalServices, ...includedServices,
      ]);
    }

    const data: Reservation = showServicesStep
      ? { ...reservationData, services }
      : reservationData;

    try {
      setLoading(true);
      trackEvent(TrackingEventEnum.SCHEDULE_RESERVATION_SUBMITTED, {
        data,
      });
      const details = await booker.postReservation(data);
      setLoading(false);
      if (details.errors) {
        handleError(details.errors);
        trackEvent(TrackingEventEnum.SCHEDULE_RESERVATION_FAILED, {
          error: details.errors,
        });
      } else {
        setPostReservationResponse(details);
        sendReservationDetailsToParentWindow(details);
        trackEvent(TrackingEventEnum.SCHEDULE_RESERVATION, {
          details,
        });
      }
    } catch (error) {
      trackEvent(TrackingEventEnum.SCHEDULE_RESERVATION_FAILED, {
        error,
      });
      handleError([error]);
      setLoading(false);
    } finally {
      dispatch({
        type: timeslotsReducer.types.invalidateTimeslotCache,
      });
    }
  };

  useEffect(() => {
    postReservation();
  }, []);

  const renderPaymentProcess = paymentsEnabled && postReservationResponse;
  const renderReservationConfirmation = !paymentsEnabled && postReservationResponse;

  return !loading ? (
    <>
      {renderPaymentProcess && <PaymentProcess reservation={postReservationResponse.reservation} />}
      {renderReservationConfirmation && <ReservationConfirmation />}
    </>
  ) : <></>;
}
