import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BookingProcess from '../../components/BookingProcess/index';
import BookingPending from '../../components/ProcessPending/index';
import Error from '../../components/Error/index';

function BookingComponent() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const tryAgain = () => {
    navigate('/');
  };

  return (
    <>
      {!error ? (
        <>
          {loading && <BookingPending processName="booking" />}
          <BookingProcess
            loading={loading}
            setLoading={setLoading}
            setError={setError}
          />
        </>
      ) : (
        <Error processName="booking" actionCallback={tryAgain} />
      )}
    </>
  );
}

export default BookingComponent;