import { useEffect } from 'react';
import ReservationConfirmation from '../ReservationConfirmation/index';
import ProcessPending from '../ProcessPending/index';
import Error from '../Error/index';

import './styles.scss';
import usePayment from '../../hooks/usePayment';

export default function PaymentProcess({ reservation }): JSX.Element {
  const { loading, success, error, finished, reset, startPayment, flowStatus } = usePayment(reservation.id);
  const processPendingComponent = <ProcessPending processName={flowStatus === 'opened' ? 'payment' : 'booking'}/>

  useEffect(() => {
    if (!loading) {
        if (!finished) {
            startPayment();
        }
    }
  }, [loading, finished]);

  return !loading
    ? (
      <>
        {
          finished
            ? (
              <>
                { success && <ReservationConfirmation /> }
                { error && <Error processName="payment" actionCallback={reset} /> }
              </>
            )
            : (
                processPendingComponent
            )
        }
      </>
    )
    : (
        processPendingComponent
    );
}
